import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";

import { Link } from "@wind/ui/components";

import { useClientVariables } from "~/hooks/useClientVariables.js";
import { ExternalLinkIcon } from "~/utils/icons.js";
import PublicMarketingApi from "../../api/PublicMarketingApi.js";

const Footer = () => {
  const { data } = useQuery({
    queryKey: ["marketing", "pages"],
    queryFn: () => new PublicMarketingApi().getPages(),
    staleTime: Infinity,
  });

  const links =
    data?.pages.map((page) => ({
      to: `/p/${page.fields.slug}`,
      title: page.fields.title,
      external: false,
    })) ?? [];

  const { PUBLIC_STATUS_PAGE_URL } = useClientVariables();

  const linksWithStatusPage = PUBLIC_STATUS_PAGE_URL
    ? [
        ...links,
        {
          to: PUBLIC_STATUS_PAGE_URL,
          title: "Status",
          external: true,
        },
      ]
    : links;

  return (
    <AnimatePresence>
      {data && (
        <motion.footer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:pt-24 lg:px-8">
            <nav
              className="-mb-6 md:columns-2 sm:flex sm:justify-center sm:gap-12"
              aria-label="Footer"
            >
              {linksWithStatusPage.map((link) => (
                <div key={link.to} className="pb-6">
                  <Link
                    to={link.to}
                    className="text-sm leading-6 hover:underline hover:underline-offset-4 text-secondary inline-flex items-center hover:text-primary truncate"
                  >
                    {link.title}
                    {link.external && (
                      <div className="opacity-50 text-xs ml-2">
                        <ExternalLinkIcon />
                      </div>
                    )}
                  </Link>
                </div>
              ))}
            </nav>
            <p className="text-secondary mt-10 text-center text-xs leading-5">
              &copy; 2024 Windmill, Inc
            </p>
          </div>
        </motion.footer>
      )}
    </AnimatePresence>
  );
};

export default Footer;
