import type { Jsonify } from "type-fest";

import type { ContentfulEntryPage } from "@wind/contentful/models";

import Api from "./Api.js";

const ROOT_PATH = "/marketing";

/**
 * Public marketing API
 *
 * @author @jchaiken1
 */
class PublicMarketingApi extends Api {
  static forLoaderRequest(request: Request) {
    return new PublicMarketingApi(Api.configForLoaderRequest(request));
  }

  async getPages(): Promise<Jsonify<{ pages: ContentfulEntryPage[] }>> {
    return this.get({ path: this.publicPath(ROOT_PATH) });
  }

  async getPage(slug: string): Promise<Jsonify<{ page: ContentfulEntryPage }>> {
    return this.get({ path: this.publicPath(`${ROOT_PATH}/${slug}`) });
  }
}

export default PublicMarketingApi;
